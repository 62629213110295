import { createTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createTypography from '@material-ui/core/styles/createTypography';
import createPalette from '@material-ui/core/styles/createPalette';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { effraRegular, effraBold, EFFRA_FAMILY_NAME, HRSD_FAMILY_NAME } from 'fonts/fonts';
import { backButtonBackgroundColor, mainBackground, shdShadow } from 'ui/styles';

const breakpointsValues = {
  xs: 0,
  sm: 320,
  md: 808,
  lg: 1224,
  xl: 1920,
};

const breakpoints = createBreakpoints({ values: breakpointsValues });
const palette = createPalette({
  primary: {
    main: '#1B8485',
    light: '#456C87',
    dark: '#001B30',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#F8A258',
    light: '#54B2B7',
    dark: '#a07e41',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#2196F3',
    dark: '#0B79D0',
    light: '#64B6F7',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#4CAF50',
    dark: '#3B873E',
    light: '#7BC67E',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#F44336',
    dark: '#E31B0C',
    light: '#F88078',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FF9800',
    dark: '#C77700',
    light: '#FFB547',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  background: {
    paper: '#FFFFFF',
    default: '#e3e3e3',
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
const typography = createTypography(palette, () => ({
  fontFamily: HRSD_FAMILY_NAME, // Default font family for everything
  body1: {
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 600,
    lineHeight: 1.75,
  },
  body2: {
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 600,
    fontSize: '1.05rem', 
    lineHeight: 1.6,
  },
  h1: { //not used ?
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 700,
    fontSize: '4rem', 
    lineHeight: 1.2,
  },
  h2: { //not used ?
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 700,
    lineHeight: 1.3,
  },
  h3: { //not used ?
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 500,
    lineHeight: 1.4,
  },
  h4: { // admin | inspector | auditor tap titles 
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 500,
    fontSize: '2.5rem',
    lineHeight: 1.4,
  },
  h5: { // hero topic
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 600,
    fontSize: '1.8rem', 
    lineHeight: 1.5,
  },
  h6: {
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 600,
    fontSize: '1.5rem', 
    lineHeight: 1.5,
  },
  h7: { //not used ?
    fontFamily: HRSD_FAMILY_NAME,
    fontWeight: 600,
    fontSize: '1.2rem', 
    lineHeight: 1.5,
  },
  button: { // not used ?
    fontFamily: HRSD_FAMILY_NAME,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.5rem', 
    lineHeight: 1.5,
  },

}));

export const theme = createTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },

      elevation1: {
        boxShadow: shdShadow,
        borderRadius: '8px',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
        fontSize: '1rem', 
        padding: '12px 24px', 
      },
      outlinedPrimary: {
        borderColor: palette.secondary.main,
      },
      outlinedSecondary: {
        backgroundColor: backButtonBackgroundColor,
        color: "#148287",
        borderColor: '#8abdc0',
        '&:hover': {
          background: '#148287',
          color: '#148287',
          borderColor: '#8abdc0',
        },
      },
      outlined: {
        fontWeight: 'bold',
        fontSize: '1rem', 
        padding: '12px 24px', 
      },

      sizeLarge: {
        fontSize: '1rem', 
        padding: '12px 24px', 
      },


      sizeSmall: {
        fontSize: '0.9rem', 
        padding: '8px 16px', 
      },

      containedSecondary: {
        boxShadow: 'none!important',
      },
    },
    MuiTableCell: {
      root: {
        ...typography.body2,
      },
      head: {
        fontWeight: 700,
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: palette.secondary.main,
        },
      },
      completed: {
        color: palette.secondary.main,
      },
    },
    MuiStepLabel: {
      label: {
        ...typography.body2,

        '&$active': {
          ...typography.subtitle2,
        },

        '&$completed': {
          ...typography.subtitle2,
        },
      },
    },

    MuiCssBaseline: {
      '@global': {
        '@font-face': [effraRegular, effraRegular, effraBold] as unknown as CSSProperties,
        body: {
          backgroundColor: '#FAFAFA',
          [breakpoints.up('md')]: mainBackground,
        },
      },
  },
    MuiTooltip: {
      tooltip: {
        fontSize: '10px',
        color: palette.primary.contrastText,
        backgroundColor: palette.primary.dark,
        padding: '10px',
        maxWidth: '240px',
      },
    },
  },
  breakpoints: {
    values: breakpointsValues,
  },
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
});
