import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { AssessmentInfo } from 'domain/admin/types/AssessmentInfo';
import { TranslationNamespace } from 'i18n/config';
import { AssessmentStatus } from 'types/AssessmentStatus';
import { useCreateTranslate } from 'utilities/translate.hook';
import { PaperCard } from 'ui/PaperCard';

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: theme.spacing(5),
  },
  answersButton: {
    flip: false,
    height: '50px',
    width: '190px',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '110px',
    },
  },
}));

export type AdminEstablishmentDetailsScoreProps = {
  showAnswersButton?: boolean;
  navigateToAnswers: () => void;
  assessmentInfo?: AssessmentInfo;
};
export const EstablishmentDetailsScore = ({
  showAnswersButton,
  navigateToAnswers,
  assessmentInfo,
}: AdminEstablishmentDetailsScoreProps) => {
  const translateAdminEstablishment = useCreateTranslate(TranslationNamespace.admin);
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);

  const classes = useStyles();

  const toSAStatusDisplay = (status: AssessmentStatus): string => {
    switch (status) {
      case AssessmentStatus.NotStarted:
        return translate('saNotStarted');
      case AssessmentStatus.Draft:
        return translate('saInProgress');
      case AssessmentStatus.AuditorStarted:
      case AssessmentStatus.PcIssued:
      case AssessmentStatus.Passed:
        return translate('passed');
      case AssessmentStatus.Failed:
        return translate('failed');
      default:
        throw Error('Unhandled SelfAssessmentStatus');
    }
  };

  const answersButton = showAnswersButton ? (
    <Button
      variant="contained"
      size='large'
      className={classes.answersButton}
      color="secondary"
      onClick={navigateToAnswers}
    >
      {translate('seeAnswers')}
    </Button>
  ) : undefined;

  return (
    <PaperCard headerNode={answersButton} title={translateAdminEstablishment('scoreSA')}>
      <>
        <Typography className={classes.text} variant="body2" color="primary">
          {assessmentInfo && assessmentInfo.status !== null && toSAStatusDisplay(assessmentInfo.status)}
        </Typography>
        <Typography variant="h6">{assessmentInfo?.score != null ? `%${assessmentInfo?.score}` : '-'}</Typography>
      </>
    </PaperCard>
  );
};
