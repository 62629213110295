import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import clsx from 'clsx';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { EditIcon, TrashIcon } from 'ui/icons/icons';
import { backButtonBackgroundColor, labelCardColor } from 'ui/styles';
import { useCreateTranslate } from 'utilities/translate.hook';
import { QuestionnaireQuestion, QuestionTypes } from 'types/QuestionnaireQuestion';
import { useDeleteQuestionMutation } from 'domain/admin/api/admin.api';

import { AdminQuestionnaireQuestionDialog } from './AdminQuestionnaireQuestionDialog';
import { getDirection, getReverseDirection } from '../../../../utilities/useLanguage';

type AdminQuestionnaireQuestionProps = {
  question: QuestionnaireQuestion;
  index: number;
  questionnaireId: number;
  sectionId: number;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  question: {
    flip: false,
    padding: `${theme.spacing(4)}px 104px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },

  questionNotEditable: {
    '&:last-of-type': {
      borderBottom: 0,
    },
  },

  buttonSpace: {
    width: '176px',
    display: 'flex',
    flexShrink: 0,
  },

  button: {
    padding: 0,
    margin: 18,
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backButtonBackgroundColor,
    borderRadius: '50%',
  },

  editButton: {
    flip: false,
    marginRight: theme.spacing(1),
  },

  svgIcon: {
    fill: theme.palette.primary.main,
  },

  questionPart: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  sectionDetails: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },

  headerText: {
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },

  textArEn: {
    flexShrink: 0,
    display: 'flex',
    maxWidth: '912px',
    flip: false,
    flexGrow: 1,
  },
  questionText: {
    fontWeight: 700,
  },

  questionDescription: {
    marginTop: theme.spacing(2),
    color: labelCardColor,
  },

  questionWeight: {
    marginTop: theme.spacing(3),
  },
}));

export const AdminQuestionnaireQuestion: React.FC<AdminQuestionnaireQuestionProps> = ({
  question,
  index,
  questionnaireId,
  sectionId,
  isEditable,
}) => {
  const classes = useStyles();
  const [openQuestionEditDialog, setOpenQuestionEditDialog] = React.useState(false);
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);
  const [deleteQuestion, { isLoading }] = useDeleteQuestionMutation();
  const direction = getDirection(i18n.language);

  const handleEdit = () => {
    setOpenQuestionEditDialog(true);
  };

  const handleCloseQuestionEditDialog = () => {
    setOpenQuestionEditDialog(false);
  };

  const handleDeleteQuestion = async () => {
    if (!isLoading) {
      await deleteQuestion({ id: question.id, questionnaireId });
    }
  };
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <div dir={reverseDirection} className={clsx(classes.question, !isEditable && classes.questionNotEditable)}>
      {isEditable && (
        <div className={classes.buttonSpace}>
          <IconButton
            className={`${classes.button} ${classes.editButton}`}
            color="inherit"
            edge="start"
            onClick={handleEdit}
          >
            <SvgIcon className={classes.svgIcon} component={EditIcon} />
          </IconButton>

          <IconButton className={classes.button} edge="start" color="inherit" onClick={handleDeleteQuestion}>
            <SvgIcon className={classes.svgIcon} component={TrashIcon} />
          </IconButton>
        </div>
      )}
      <div dir={direction} className={classes.questionPart}>
        <div className={classes.headerText}>
          <div className={classes.textArEn}>
            <Typography variant="body2" className={classes.questionText}>
              {`${index + 1}. ${i18n.language === 'en' ? question.textEN : question.textAR}`}
            </Typography>
          </div>
        </div>
        <div className={classes.headerText}>
          <div className={classes.textArEn}>
            <Typography variant="body2" className={classes.questionDescription}>
              {i18n.language === 'en' ? question.descriptionEN : question.descriptionAR}
            </Typography>
          </div>

        </div>
        <Typography variant="body2" className={`${classes.questionText} ${classes.questionWeight}`}>
          {`${translateQuestionnaire('questionWeight')}: ${question.weight}`}
        </Typography>
        <Typography variant="body2" className={`${classes.questionText} ${classes.questionWeight}`}>
          {`${translateQuestionnaire('questionType')}: ${translateQuestionnaire(
            QuestionTypes.filter((type) => type.value === question.type)[0].name
          )}`}
        </Typography>
      </div>
      <AdminQuestionnaireQuestionDialog
        open={openQuestionEditDialog}
        handleClose={handleCloseQuestionEditDialog}
        sectionId={sectionId}
        questionnaireId={questionnaireId}
        question={question}
      />
    </div>
  );
};