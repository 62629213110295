import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { ArrowForwardIcon, SaveIcon } from 'ui/icons/icons';
import { shdShadow } from 'ui/styles';

import { CompanyRepresentativeSASubmitDialog } from './CompanyRepresentativeSASubmitDialog';
import { useGetAssessmentByIdQuery } from '../api/companyRepresentative.api';
import { getDirection } from '../../../utilities/useLanguage';

type PropTypes = {
  handleGoToEstablishmentList: () => void;
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  isSummary: boolean;
  sectionNames: string[];
  assessmentId: number;
  isSubmitDisabled: boolean;
};

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    boxShadow: shdShadow,
  },

  leftButton: {
    flip: false,
    height: '50px',
    width: '187px',
    marginRight: theme.spacing(2),
  },

  rightButton: {
    flip: false,
    height: '50px',
    width: '209px',
    marginRight: theme.spacing(4),
  },

  rightButtonIcon: {
    flip: false,
    marginLeft: theme.spacing(1),
  },

  saveDraft: {
    flip: false,
    flexShrink: 0,
    textAlign: 'left',
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },

  saveDraftButton: {
    flip: false,
    alignItems: 'center',
    padding: 0,
  },

  saveDraftIcon: {
    flip: true,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },

  select: {
    flexGrow: 1,
  },
}));

export const CompanyRepresentativeSAStepper: React.FC<PropTypes> = ({
  handleGoToEstablishmentList,
  isSummary,
  stepIndex,
  setStepIndex,
  sectionNames,
  assessmentId,
  isSubmitDisabled,
}) => {
  const classes = useStyles();
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const translateSA = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const [openSubmit, setOpenSubmit] = React.useState(false);
  const { data: assessment } = useGetAssessmentByIdQuery(assessmentId);
  const direction = getDirection(i18n.language);

  const changeSection = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStepIndex(event.target.value as number);
  };

  const goNextSection = () => {
    setStepIndex(stepIndex + 1);
  };

  const goPrevSection = () => {
    setStepIndex(stepIndex - 1);
  };

  const handleOpenSubmitDialog = () => {
    setOpenSubmit(true);
  };

  const handleCloseSubmitDialog = () => {
    setOpenSubmit(false);
  };

  return (
    <Paper dir={direction} className={classes.stepper}>
      {isSummary ? (
        <Button
          key={`saSummary=submit-${isSubmitDisabled}`}
          disabled={isSubmitDisabled}
          variant="contained"
          size="large"
          className={classes.leftButton}
          color="secondary"
          onClick={handleOpenSubmitDialog}
        >
          {translateSA('submit')}
        </Button>
      ) : (
        <Button
          variant="contained"
          size="large"
          className={classes.leftButton}
          color="secondary"
          onClick={goNextSection}
        >
          {translateSA('nextSection')}
        </Button>
      )}
      {stepIndex !== 0 ? (
        <Button
          variant="outlined"
          size="large"
          className={classes.rightButton}
          color="secondary"
          disabled={stepIndex === 0}
          key={`previous-section-button-${stepIndex === 0}`}
          onClick={goPrevSection}
        >
          {translateSA('prevSection')}
          <SvgIcon className={classes.rightButtonIcon} component={ArrowForwardIcon} />
        </Button>
      ) : (
        <div className={classes.rightButton}></div>
      )}
      <div className={classes.saveDraft}>
        <Button
          className={classes.saveDraftButton}
          color="primary"
          size="large"
          onClick={handleGoToEstablishmentList}
        >
          <SvgIcon className={classes.saveDraftIcon} component={SaveIcon} />
          {translateCommon('saveAndExit')}
        </Button>
      </div>
      {sectionNames.length > 0 && (
        <Select
          className={classes.select}
          variant="outlined"
          dir={direction}
          value={stepIndex}
          onChange={changeSection}
        >
          {sectionNames.map((section, index) => (
            <MenuItem key={`sectionSelect-${section}`} value={index}>
            {translateSA('section')} {index + 1}: {section.length > 50 ? `${section.substring(0, 50)}...` : section}
          </MenuItem>
          ))}
          <MenuItem key="sectionSelect-summary-checklist" value={sectionNames.length}>
            {translateSA('saSummary')}: {translateSA('checklist')}
          </MenuItem>
        </Select>
      )}
      {assessment && (
        <CompanyRepresentativeSASubmitDialog
          open={openSubmit}
          handleClose={handleCloseSubmitDialog}
          assessmentId={assessmentId}
          notApplicableRatioExceeded={assessment.notApplicableRatioExceeded}
        />
      )}
    </Paper>
  );
};
