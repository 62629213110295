import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Paper,
} from '@material-ui/core';
import i18n from 'i18next';

import { LoadingSpinner } from 'ui/LoadingSpinner';
import { useNavigate } from 'utilities/navigate';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { Button } from 'ui/Button';
import { CustomTablePagination } from 'ui/CustomTablePagination';

import { EstablishmentNoData } from './EstablishmentNoData';
import { EstablishmentStatusTableCell } from './EstablishmentStatusTableCell';
import { useGetEstablishmentsListQuery } from '../../../admin/api/admin.api';
import { Routes } from '../../Routes';
import { getDirection, getReverseDirection } from '../../../../utilities/useLanguage';

type EstablishmentsTableProps = {
  getPath: (value: Routes) => string;
  searchEstablishment: string;
};

const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.sm,
  },

  button: {
    fontSize: '14px',
    color: theme.palette.primary.main,
  },

  action: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellPadding: {
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(10)}px`,
  },
  cellNo: {
    paddingRight: '130px',
    paddingLeft: '120px',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'center',
  },
}));

export const EstablishmentsTable: React.FC<EstablishmentsTableProps> = ({ getPath, searchEstablishment }) => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const classes = useStyle();
  const navigate = useNavigate();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const direction = getDirection(i18n.language);
  const { data: { count, results } = { count: 0, results: [] }, isLoading } = useGetEstablishmentsListQuery({
    parameters: { page: page + 1, pageSize },
    param: { searchInfo: [searchEstablishment] },
  });

  const showEstablishmentDetails = (establishmentId: number) => {
    navigate(`${getPath(Routes.establishment)}/${establishmentId}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const reverseDirection = getReverseDirection(i18n.language);

  return results.length > 0 ? (
    <div dir={reverseDirection}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="medium">
          <TableHead>
            <TableRow>
              <TableCell className={classes.action} style={{ width: '160px', maxWidth: '160px' }}>
                {translate('establishmentActions')}
              </TableCell>
              <TableCell className={classes.right} style={{ width: '316px', maxWidth: '316px' }}>
                {translate('establishmentStatus')}
              </TableCell>
              <TableCell className={classes.right} style={{ width: '250px', maxWidth: '250px' }}>
                {translate('establishmentScore')}
              </TableCell>
              <TableCell className={classes.cellPadding} style={{ width: '300px', maxWidth: '300px' }}>
                {translate('establishmentEconomicActivity')}
              </TableCell>
              <TableCell className={classes.cellPadding} style={{ minWidth: '250px' }}>
                {translate('establishmentName')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((establishmentDetails) => (
              <TableRow key={establishmentDetails.id}>
                <TableCell className={classes.action}>
                  <Button
                    onClick={() => showEstablishmentDetails(establishmentDetails.id)}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className={classes.button}
                  >
                    {translate('establishmentViewDetails')}
                  </Button>
                </TableCell>
                <EstablishmentStatusTableCell
                  className={classes.cellPadding}
                  status={establishmentDetails.assessmentCurrentObjStatusOSH}
                />
                <TableCell className={`${classes.cellNo} ${i18n.language === 'ar' ? classes.right : classes.left}`}>
                  {establishmentDetails.assessmentCurrentObjScore
                    ? `${establishmentDetails.assessmentCurrentObjScore}%`
                    : '-'}
                </TableCell>
                <TableCell className={classes.cellPadding}>{establishmentDetails.economicActivityNameAr}</TableCell>
                <TableCell className={classes.cellPadding}>{establishmentDetails.nameAr}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <CustomTablePagination
                dir={direction}
                rowsPerPageOptions={[5, 10, 25]}
                count={count}
                rowsPerPage={pageSize}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  ) : isLoading ? (
    <LoadingSpinner type='admin'/>
  ) : (
    <EstablishmentNoData />
  );
};
