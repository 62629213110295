export const saudiPhoneNumberRegex = /^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/g;
export const phoneNumberRegex = /^([0-9]{7,12})$/;
export const taxNumberRegex = /^3[0-9]{13}3$/g;
export const extensionNumberRegex = /^[0-9]{1,5}$/g;
// based on https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const emailRegex =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
export const scoreRegex = /^\d{1,6}(\.\d{0,2})?$/;
export const numberPlusRegex = /^[\d+]/;
export const numberRegex = /^[\d]/;
export const enNameRegex = /\b.*[a-zA-Z]+.*\b/;
export const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
export const symbolCaseRegex = /[!@#$%^&*]/;
export const oneNumberRegex = /[0-9]/;
export const upperCaseRegex = /[A-Z]/;
export const lowerCaseRegex = /[a-z]/;
export const enInputRegex = /^[A-Za-z0-9.,?!:;()'" \s؟?-]*$/;
export const arInputRegex = /^[^\u0041-\u005A\u0061-\u007A]+$/;

export const linkedInUrlRegex = /^https:\/\/www\.linkedin\.com\//;

export const xUrlRegex = /^https:\/\/x\.com\//;

export const twitterUrlRegex = /^https:\/\/twitter\.com\//;

export const facebookUrlRegex = /^https:\/\/www\.facebook\.com\//;
