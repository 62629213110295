import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { usePostAttachmentMutation } from 'domain/company-representative/api/companyRepresentative.api';

import { CheckGreyIcon, CrossCloseGreyIcon } from './icons/icons';
import { Hint } from './Hint';

const Input = styled('input')({
  display: 'none',
});

interface MissingInfoAttachmentsProps {
  hasTaxNumber: string;
  direction: string;
  classes: any;
  translate: (key: string) => string;
  establishment: any;
}

const MissingInfoAttachments = forwardRef(
  ({ hasTaxNumber, direction, classes, translate, establishment }: MissingInfoAttachmentsProps, ref) => {
    const [attachments, setAttachment] = useState<File[]>([]);
    const [maxAttachmentsSelected, setMaxAttachmentsSelected] = useState(false);
    const establishmentId = establishment.id;
    const [postAttachment] = usePostAttachmentMutation();

    const handleFileChange = async ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      if (files && files.length) {
        const newFiles = Array.from(files);
        if (newFiles.length > 5) {
          console.error('Cannot add more than 5 attachments at once');
          setMaxAttachmentsSelected(true);
          return;
        }
        const totalFiles = attachments.length + newFiles.length;
        if (totalFiles > 5) {
          console.error('Cannot add more than 5 attachments in total');
          setMaxAttachmentsSelected(true);
          return;
        }
        setAttachment((existing) => existing.concat(newFiles));
        setMaxAttachmentsSelected(false);
      }
    };

    const removeAttachment = (id: number) => {
      const updatedAttachments = attachments.filter((_, i) => i !== id);
      setAttachment(updatedAttachments);

      if (updatedAttachments.length < 5) {
        setMaxAttachmentsSelected(false);
      }
    };

    const submitAttachments = async () => {
      if (attachments.length === 0) {
        console.error('No attachments found');
        return false;
      }

      const formData = new FormData();
      attachments.forEach((file) => {
        formData.append('file', file, file.name);
      });

      try {
        const response = await postAttachment({ establishmentId, data: formData });
        if ('error' in response) {
          console.error('Error submitting Attachment:', response.error);
          return false;
        } else {
          console.log('Attachment response:', response.data);
          return true;
        }
      } catch (error) {
        console.error('Error submitting Attachment:', error);
        return false;
      }
    };

    useImperativeHandle(ref, () => ({
      submitAttachments,
    }));

    return (
      <div className={classes.sectionHeader}>
        <Typography variant="h6" className={classes.field}>
          {translate('uploadDocuments')}
        </Typography>
        <Typography variant="body2" className={classes.field}>
          {hasTaxNumber === 'yes' ? translate('uploadTaxCertificate') : translate('uploadNonTaxCertificate')}
        </Typography>
        <div dir={direction} className={classes.uploadButtonSpace}>
          <label>
            <Input
              type="file"
              onChange={handleFileChange}
              accept=".png,.jpg,.jpeg,.pdf,.heic,.hevc,.heif"
              multiple
              disabled={attachments.length >= 5}
            />
            <Button
              size="large"
              variant="outlined"
              component="span"
              color="secondary"
              className={`${classes.uploadButton} ${attachments.length >= 5 ? 'disabled' : ''}`}
              onClick={(e) => {
                if (attachments.length >= 5) {
                  setMaxAttachmentsSelected(true);
                  e.preventDefault();
                }
              }}
            >
              {translate('upload')}
            </Button>
          </label>
          <Typography dir="rtl" variant="body2" className={classes.uploadButtonDesc}>
            {translate('maximum5Files')} {translate('filesTypes')}
          </Typography>
        </div>
        <div dir={direction} className={classes.uploadedContainer}>
          {attachments.map((file, i) => (
            <Button
              key={i}
              variant="outlined"
              size="medium"
              color="secondary"
              className={classes.attachment}
              startIcon={<CheckGreyIcon fill="#148287" />}
              endIcon={<CrossCloseGreyIcon fill="#9E9E9E" onClick={() => removeAttachment(i)} />}
            >
              {file.name}
            </Button>
          ))}
        </div>
        {maxAttachmentsSelected ? <Hint type="error" text={translate('maxAttachmentsSelected')} /> : null}
      </div>
    );
  }
);

export default MissingInfoAttachments;
