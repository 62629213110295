import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { AssessmentQuestion } from 'types/AssessmentQuestion';

import { AssessmentAnswerMark } from './AssessmentAnswerMark';
import { getDirection } from '../../utilities/useLanguage';

type AssessmentQuestionTextProps = {
  questionNo: number;
  question: AssessmentQuestion;
  isVisibleMark?: boolean;
};

const useStyles = makeStyles((theme) => ({
  questionText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
}));

export const AssessmentQuestionText: React.FC<AssessmentQuestionTextProps> = ({
  question,
  questionNo,
  isVisibleMark,
}) => {
  const classes = useStyles();
  const direction = getDirection(i18n.language);

  return (
    <div dir={direction} className={classes.questionText}>
      <Typography variant="h6">
        {questionNo}. {i18n.language === 'en' ? question.textEN : question.textAR}
      </Typography>

      <AssessmentAnswerMark isValid={question.isValidAnswer} isVisible={isVisibleMark} />
    </div>
  );
};
