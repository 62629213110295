import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';

type CustomMenuItemProps = {
  onClick: () => void;
  children?: React.ReactNode;
};

const StyledMenuItem = withStyles((theme) => ({
  root: {
    minWidth: '168px',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
    },
    '& .MuiListItemText-root': {
      flip: false,
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: 'rgba(20,130,135, 0.08)',
      '& .MuiListItemText-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.main,

        '& path': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
}))(MenuItem);

export const CustomMenuItem = React.forwardRef(
  ({ children, onClick }: CustomMenuItemProps, ref: React.Ref<HTMLLIElement>) => (
    <StyledMenuItem ref={ref} onClick={onClick}>
      {children}
    </StyledMenuItem>
  )
);
