import React, { useCallback, useRef, useState } from 'react';
import { Link, makeStyles, Theme, Typography, useMediaQuery, useTheme, SvgIcon } from '@material-ui/core';
import i18n from 'i18next';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { ReactComponent as PhoneIcon } from 'ui/icons/phone-line-icon.svg';
import LogoMono from 'images/logo-mono.svg';
import LogoMonoEng from 'images/logo-monoEng.svg';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  footer: {
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingInline: 40,
    },
  },

  links: {
    textDecoration: 'none',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  logo: {
    height: 40,
    [theme.breakpoints.up('md')]: {
      height: 48,
    },
  },
  language: {
    color: theme.palette.common.white,
    fontSize: '15px',
    [theme.breakpoints.down(1180)]: {
      fontSize: '10px',
    },
  },
  copy: {
    marginLeft: 'auto',
  },
  phone: {
    marginRight: theme.spacing(4),
  },
  number: {
    marginLeft: theme.spacing(4),
  },
  phoneEN: {
    marginLeft: theme.spacing(4),
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);
  const logo = i18n.language === 'ar' ? LogoMono : LogoMonoEng;
    const throwError = () => {
    throw new Error('Test error for Sentry & source maps');
  };

  return (
    <div className={classes.container}>
      <footer className={classes.footer}>
        <img src={logo} alt="Logo-mono" className={classes.logo} />
        <Typography className={classes.links}>
          <Link href="/terms" className={classes.links}>
            {translate('termsAndConditions')}
          </Link>
        </Typography>
        <Typography className={classes.links}>
          <Link href="/qan" className={classes.links}>
            {translate('questionAndAnswers')}
          </Link>
        </Typography>
        <Typography className={classes.links}>
          <Link href="/privacy" className={classes.links}>
            {translate('privacyPolicy')}
          </Link>
        </Typography>
        {i18n.language === 'ar' ? (
          <>
            <Typography className={classes.number} style={{ direction: 'rtl' }}>
              <a href="tel:920003465" style={{ color: 'white' }}>
                3465 92000
              </a>
            </Typography>
            <SvgIcon className={classes.phone} component={PhoneIcon} viewBox="0 0 170 150" />
          </>
        ) : (
          <>
            <SvgIcon className={classes.phoneEN} component={PhoneIcon} viewBox="0 0 170 150" onClick={throwError} />
            <Typography style={{ direction: 'rtl' }}>
              <a href="tel:920003465" style={{ color: 'white' }}>
                3465 92000
              </a>
            </Typography>
          </>
        )}
        <Typography className={classes.copy} variant="body2">
          {translate('copyright')} &copy; {new Date().getFullYear()}
        </Typography>
      </footer>
    </div>
  );
};

export default Footer;
