import FrutigerLight from './FrutigerLTArabic-45Light.woff';
import FrutigerRegular from './FrutigerLTArabic-55Roman.woff';
import FrutigerBold from './FrutigerLTArabic-65Bold.woff';
import EffraRegular from './Effra_Std_Rg.woff2';
import EffraBold from './Effra_Std_Bd.woff2';
import HRSDRegularFont from './HRSD-Regular.ttf';
import HRSDTitleFont from './HRSD-Title.ttf';
import HRSDBoldFont from './HRSD-Bold.ttf';
import HRSDMediumFont from './HRSD-Medium.ttf';
import HRSDLightFont from './HRSD-Light.ttf';
import HRSDThinFont from './HRSD-Thin.ttf';



export const FRUTIGER_FAMILY_NAME = 'Frutiger';
export const EFFRA_FAMILY_NAME = 'Effra';
export const HRSD_FAMILY_NAME = 'HRSD';

export const frutigerLight = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${FrutigerLight}) format('woff')`,
};

export const frutigerRegular = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${FrutigerRegular}) format('woff')`,
};

export const frutigerBold = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${FrutigerBold}) format('woff')`,
};

export const effraRegular = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${EffraRegular}) format('woff2')`,
};

export const effraBold = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${EffraBold}) format('woff2')`,
};

export const HRSDRegular = {
  fontFamily: HRSD_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${HRSDRegularFont}) format('ttf')`,
};

export const HRSDTitle = {
  fontFamily: HRSD_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${HRSDTitleFont}) format('ttf')`,
};

export const HRSDBold = {
  fontFamily: HRSD_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${HRSDBoldFont}) format('ttf')`,
};

export const HRSDMedium = {
  fontFamily: HRSD_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${HRSDMediumFont}) format('ttf')`,
};

export const HRSDLight = {
  fontFamily: HRSD_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${HRSDLightFont}) format('ttf')`,
};

export const HRSDThin = {
  fontFamily: HRSD_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `url(${HRSDThinFont}) format('ttf')`,
};


