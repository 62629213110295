import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { Establishment } from 'types/Establishment';
import { Map } from 'ui/Map';
import { PaperCard } from 'ui/PaperCard';
import { notAvailableInfo } from 'utilities/notAvailableInfo';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DownloadIcon } from '../../../../ui/icons/icons';
import { getReverseDirection as getReverseDirectionForAssessmentResultQuestionAttachments } from '../../../../utilities/useLanguage';
import i18n from 'i18next';

const useStyle = makeStyles((theme) => ({
  details: {
    flip: false,
    marginTop: theme.spacing(4),
  },
  establishmentAttachments: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    flip: false,
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
  },
  establishmentText: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '400px',
    alignItems: 'center',
  },
  establishmentName: {
    flip: false,
    flexGrow: 1,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

type AdminEstablishmentDetailsProps = {
  establishment: Establishment;
};

export const EstablishmentDetailsBody: React.FC<AdminEstablishmentDetailsProps> = ({ establishment }) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const attachmentName = establishment.attachments[0]?.name;
  const reverseDirection = getReverseDirectionForAssessmentResultQuestionAttachments(i18n.language);
  const classes = useStyle();

  const geolocation = {
    lat: Number(establishment.latitude),
    lng: Number(establishment.longitude),
  };

  const downloadAttachmentFn = (establishmentId: number, documentId: number) => async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const downloadUrl = `${baseUrl}/api/establishment/${establishmentId}/document/${documentId}/download/`;

      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error('Token not found');
      }

      const response = await axios({
        url: downloadUrl,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = new Blob([response.data]);
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;

      link.download = attachmentName;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the attachment:', error);
    }
  };

  const companyRepresentativeDetails = [
    {
      name: translate('CRName'),
      value: establishment.companyRepresentativeName,
    },
    {
      name: translate('email'),
      value: establishment.email,
    },
    {
      name: translate('phoneNumber'),
      value: establishment.phoneNumber,
    },
    {
      name: translate('phoneNumberAdditional'),
      value: notAvailableInfo(establishment.additionalPhoneNumber),
    },
  ];

  const companyDetails = [
    {
      name: translate('companyNameEn'),
      value: establishment.nameEn,
    },
    {
      name: translate('companyNameAr'),
      value: establishment.nameAr,
    },
    {
      name: translate('taxNumber'),
      value: establishment.taxNumber,
    },
    {
      name: translate('economicActivity'),
      value: establishment.economicActivityNameAr,
    },
    {
      name: translate('phoneNumber'),
      value: establishment.phoneNumber,
    },
    {
      name: translate('totalLaborerCount'),
      value: establishment.totalLaborerCount,
    },
    {
      name: translate('commercialRegistration'),
      value: establishment.commercialRegistration,
    },
  ];

  return (
    <div>
      <PaperCard title={translate('CRDetails')} data={companyRepresentativeDetails} />
      <PaperCard title={translate('companyDetails')} data={companyDetails} />
      <PaperCard title={translate('googleLocation')}>
        <div className={classes.details}>
          <Map markerPosition={geolocation} disabled={true} />
        </div>
      </PaperCard>
      <PaperCard title={translate('uploadAttachments')}>
        <div className={classes.details}>
          {establishment.attachments.length > 0 ? (
            <div dir={reverseDirection} className={classes.establishmentAttachments}>
              {establishment.attachments.map((attachment) => (
                <Paper
                  className={classes.establishmentAttachments}
                  key={`result-answer-attachment-${attachment.id}`}
                  variant="outlined"
                >
                  <div className={classes.establishmentText}>
                    <Typography noWrap className={classes.establishmentName} variant="body2">
                      {attachment.name}
                    </Typography>
                    <IconButton color="secondary" onClick={downloadAttachmentFn(establishment.id!, attachment.id!)}>
                      <SvgIcon component={DownloadIcon} />
                    </IconButton>
                  </div>
                </Paper>
              ))}
            </div>
          ) : null}
        </div>
      </PaperCard>
    </div>
  );
};
