import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { CrFillMissingInfoForm } from 'domain/company-representative/company-representative-missing-info/CrFillMissingInfoForm';
import { toFormValues } from 'domain/company-representative/types/MissingInfo';
import { Layout } from 'ui/Layout';
import { LoadingWrapper } from 'ui/LoadingWrapper';
import {
  useGetEconomicActivitiesListQuery,
  useGetEstablishmentInfoQuery,
  useGetRegionListQuery,
} from 'domain/company-representative/api/companyRepresentative.api';
import {
  CompanyRepresentativeRoutes,
  getCompanyRepresentativePath,
} from 'domain/company-representative/CompanyRepresentativeRoutes';

export const FillMissingInfo: React.FC = () => {
  const { extId } = useParams<{ extId: string }>();
  const { data: establishmentInfo, isLoading: isFormLoading } = useGetEstablishmentInfoQuery(extId);
  const { data: economicActivities, isLoading: areActivitiesLoading } = useGetEconomicActivitiesListQuery();
  const { data: regionOptions, isLoading: areRegionsLoading } = useGetRegionListQuery();

  if (establishmentInfo?.isUpdated) {
    return (
      <Redirect
        to={`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/${establishmentInfo.id}`}
      />
    );
  }

  return (
    <Layout>
      <LoadingWrapper isLoading={isFormLoading || areActivitiesLoading || areRegionsLoading}>
        <CrFillMissingInfoForm
          formValues={toFormValues(establishmentInfo || {})}
          economicActivities={economicActivities || []}
          regionOptions={
            regionOptions
              ? regionOptions.map((region) => ({
                  ...region,
                  cities: (region as any).cities || [], // 🔹 TypeScript workaround
                }))
              : []
          }
        />
      </LoadingWrapper>
    </Layout>
  );
};
