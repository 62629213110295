import { makeStyles, Paper, SvgIcon, SvgIconTypeMap, Typography } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React, { isValidElement } from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import i18n from 'i18next';

import { EstablishmentEditDialog } from 'domain/company-representative/company-representative-establishment/CREstablishmentEditDialog';
import { EditIcon, GeneralInfoDocumentIcon } from 'ui/icons/icons';
import { WithChildren } from 'interfaces/WithChildren';
import { backButtonBackgroundColor, shdShadow } from 'ui/styles';

import { FieldData } from '../types/FieldData';
import { FormValues } from '../domain/company-representative/types/MissingInfo';
import { getReverseDirection as getReverseDirectionForPaperCard } from '../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    boxShadow: shdShadow,
    marginTop: theme.spacing(5),
    /*
      Always take 100% of available space ( not including top margin )
      It can be overwritten by custom paperCardClassName
    */
    height: `calc(100% - ${theme.spacing(5)}px)`,
  },
  paperContainerReversed: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  },
  header: {
    flip: false,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(4)}px`,
  },
  headerIcon: {
    flip: true,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '48px',
    height: '48px',
    fill: 'none',
  },
  details: {
    flip: false,
    marginTop: theme.spacing(4),
  },
  headerNode: {
    flex: 1,
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  grid: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '144px',
  },

  titleColor: {
    color: theme.palette.text.secondary,
  },
  titleColorReversed: {
    color: theme.palette.common.white,
  },
  bodyNoMargin: {
    paddingInline: 0,
    paddingBottom: 0,
  },
  bar: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  dataBodyPadding: {
    padding: `0 ${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  reversedColorIcon: {
    '& circle': {
      stroke: theme.palette.common.white,
    },
    '& path': {
      fill: theme.palette.common.white,
    },
  },
  buttonSpace: {
    width: '176px',
    display: 'flex',
    flexShrink: 0,
  },

  button: {
    padding: 0,
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backButtonBackgroundColor,
    borderRadius: '50%',
  },

  editButton: {
    flip: false,
    marginRight: theme.spacing(3),
  },

  svgIcon: {
    fill: theme.palette.secondary.main,
  },
}));

type CustomIcon =
  | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  | React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;

type PaperCardProps = {
  title: string;
  customIcon?: CustomIcon;
  data?: FieldData[];
  headerNode?: React.ReactElement;
  withGrid?: boolean;
  reversedColors?: boolean;
  withBodyPadding?: boolean;
  paperCardClassName?: string;
  menuButtonWrapper?: boolean;
  isEditable?: boolean;
  isFormOpen?: boolean;
  formValues?: Partial<FormValues>;
};
export const PaperCard = ({
  title,
  customIcon,
  data,
  children,
  headerNode,
  withGrid,
  reversedColors,
  menuButtonWrapper,
  paperCardClassName,
  isFormOpen,
  isEditable,
  withBodyPadding = true,
  formValues,
  ...rest
}: WithChildren<PaperCardProps>) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const reverseDirection = getReverseDirectionForPaperCard(i18n.language);

  return (
    <Paper
      className={clsx(classes.paperContainer, reversedColors && classes.paperContainerReversed, paperCardClassName)}
    >
      <div dir={reverseDirection} className={classes.header} {...rest}>
        {headerNode && <div className={classes.headerNode}> {headerNode} </div>}

        {isEditable && (
          <div className={classes.buttonSpace && classes.headerNode}>
            <IconButton
              className={`${classes.button} ${classes.editButton}`}
              color="inherit"
              edge="start"
              onClick={handleClickOpen}
            >
              <SvgIcon className={classes.svgIcon} component={EditIcon} />
            </IconButton>
            <EstablishmentEditDialog isFormOpen={open} handleClose={handleClose} formValues={formValues} />
          </div>
        )}

        <Typography dir={reverseDirection} variant="h5">
          {title}
        </Typography>
        <SvgIcon
          className={clsx(classes.headerIcon, reversedColors && classes.reversedColorIcon)}
          component={customIcon || GeneralInfoDocumentIcon}
          viewBox="0 0 48 48"
        />
      </div>
      <div className={classes.bar}></div>
      <div className={clsx({ [classes.grid]: withGrid, [classes.dataBodyPadding]: withBodyPadding })}>
        {data
          ? data.map(({ name, value, className }) => {
              // If value is null we do not render it.
              // This can be very handy to avoid duplication of data prop for different cases.
              if (value === null) return null;

              if (isValidElement(value)) {
                return value;
              }

              return (
                <div key={name} className={classes.details}>
                  <Typography
                    variant="body2"
                    className={clsx(classes.titleColor, reversedColors && classes.titleColorReversed)}
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={className}>
                    {value}
                  </Typography>
                </div>
              );
            })
          : children}
      </div>
    </Paper>
  );
};
