import React, { FormEventHandler, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import i18n from 'i18next';

import { shdShadow } from 'ui/styles';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { PlusCircleOutlineIcon } from 'ui/icons/icons';
import { AssessmentTemplate } from 'domain/admin/types/AssessmentTemplate';
import { isQuestionnaireDraftOrMissing } from 'domain/admin/utils/QuestionnaireUtils';

import { AdminQuestionnaireStepProps } from '../AdminQuestionnaireStepOne';
import { AdminQuestionnaireSectionsDialog } from './AdminQuestionnaireSectionsDialog';
import { useGetSectionsByQuestionnaireIdQuery } from '../../api/admin.api';
import { AdminQuestionnaireSection } from './AdminQuestionnaireSection';
import { getDirection } from '../../../../utilities/useLanguage';

type AdminQuestionnaireSectionsProps = AdminQuestionnaireStepProps & {
  questionnaire: AssessmentTemplate;
};

const useStyles = makeStyles((theme) => ({
  section: {
    boxShadow: shdShadow,
    marginTop: theme.spacing(5),
    padding: `${theme.spacing(4)}px`,
  },

  addSection: {
    paddingTop: '42px',
    paddingDown: '36px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  addSectionButton: {
    fontWeight: 700,
    flip: false,
    display: 'flex',
    padding: 0,
  },

  addSectionIcon: {
    fill: theme.palette.primary.main,
    flip: false,
    width: '40px',
    height: '40px',
    marginRight: 4,
    marginLeft: 4,
  },
}));

export const AdminQuestionnaireSections: React.FC<AdminQuestionnaireSectionsProps> = ({
  goNextStep,
  questionnaire,
}) => {
  const classes = useStyles();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);
  const [open, setOpen] = React.useState(false);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const { data: sections } = useGetSectionsByQuestionnaireIdQuery({ questionnaireId: questionnaire.id });
  const direction = getDirection(i18n.language);

  const handleClickOpen = () => {
    setOpen(true);
    setFirstLoad(false);
  };

  const handleClose = () => {
    setFirstLoad(false);
    setOpen(false);
    setFirstLoad(false);
  };

  useEffect(() => {
    if (sections && sections.length === 0 && firstLoad) {
      setOpen(true);
    }
  }, [sections, open, firstLoad]);

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    goNextStep(event);
  };

  return (
    <div>
      <form id="stepForm" onSubmit={onSubmit} />
      {sections &&
        sections.map((section, index) => (
          <AdminQuestionnaireSection
            key={`questionnaire_section_${section.id}`}
            section={section}
            index={index}
            totalCount={sections.length}
            questionnaire={questionnaire}
          />
        ))}
      {isQuestionnaireDraftOrMissing(questionnaire) && (
        <Paper className={`${classes.section} ${classes.addSection}`}>
          <div dir={direction}>
            <Button
              className={classes.addSectionButton}
              color="primary"
              size="large"
              variant="text"
              onClick={handleClickOpen}
            >
              <SvgIcon className={classes.addSectionIcon} component={PlusCircleOutlineIcon} viewBox="0 0 40 40" />
              {translateQuestionnaire('addSection')}
            </Button>
          </div>
        </Paper>
      )}
      <AdminQuestionnaireSectionsDialog open={open} handleClose={handleClose} questionnaireId={questionnaire.id} />
    </div>
  );
};
