import React, { useState } from 'react';
import { Link, makeStyles, Theme, Typography, useMediaQuery, useTheme, Button, Card, CardContent, Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import { shdShadow } from 'ui/styles';
import { CollapseButton } from 'ui/buttons/CollapseButton';

import LogoColor from 'images/logo-color.svg';

import OshStripes from '../../images/osh-stripes.svg';

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(10),
    padding: `0 ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    justifyContent: 'flex-end',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 40,
    [theme.breakpoints.up('md')]: {
      height: 48,
    },
  },
  title: {
    padding: '2%',
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  
  subtitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  li: {
    padding: theme.spacing(2),
  },
  list: {
    maxWidth: '1000px',
  },
  addPadding: {
    padding: '0 10%',
  },
  addbottom: {
    display: 'block',
    padding: '1% 0 3%',
    textAlign: 'justify',
    lineHeight: '2',
    fontSize: '1.2em',
  },
  backButtonFag: {
    margin: theme.spacing(1),
    color: theme.palette.common.white,
    textDecoration: 'none',
    display:"flex",
    justifyContent:"end",
    alignItems:'center',
  },
  downPage: {
      marginBottom: theme.spacing(5)
  },
  items: {
      marginBottom: theme.spacing(5)
  },
  answerGroup:{
      marginBottom: theme.spacing(3),
      color: '#999999',
      marginTop: theme.spacing(1)
  },
   panelCard: {
    borderRadius: theme.spacing(2),
    border: 0,
    backgroundColor: '#fff',
    boxShadow: shdShadow,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  box: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:'center',
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));
interface FAQ {
  question: string;
  answer: React.ReactNode;
}
interface FAQSection {
  title: string;
  faqs: FAQ[];

}
export const QuestionAndAnswerPage: React.FC = () => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);
  const theme = useTheme();
  const classes = useStyles();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const history = useHistory();
  const handleBackToHome = () => {
    history.replace('/');
  };
  const faqSections: FAQSection[] = [
    {
      title: 'عن المنصة',
      faqs: [
        {
          question: 'ما هي منصة تقييم السلامة والصحة المهنية؟',
          answer: 'هي منصة تهدف إلى تحقيق أعلى درجات الامتثال لمتطلبات السلامة والصحة المهنية التي تم تحديدها من قبل المجلس الوطني للسلامة والصحة المهنية من خلال عملية متكاملة تبدأ بتقييم المنشأة لنفسها ذاتيًّا وتنتهي بالتدقيق المقدم من أحد مزودي الخدمة المعتمدين.'
        },
        {
          question: 'ماذا تقدم المنصة لي ؟',
          answer: (
            <ul>
              <li>من خلال المنصة ستتعرف المنشآت على مدى التزامها بمتطلبات السلامة والصحة المهنية.</li>
              <li>حمايتها من التعرض للخسائر في الأرواح والممتلكات الناجمة عن الممارسات الخاطئة.</li>
              <li>رفع الوعي لدى المنشآت فيما يخصّ متطلّبات السلامة والصحة المهنية.</li>
            </ul>
          )
        }
      ]
    },
    {
      title:'التقييم الذاتي للسلامة والصحة المهنية',
      faqs: [
        {
          question: 'لماذا يتوجب على المنشأة القيام بالتقيّيم الذاتي؟',
          answer: 'التقييم الذاتي يساعد المنشأة من التعرّف على متطلبات السلامة والصحة المهنية وتصحيح الأخطاء ذاتيًّا مما يحميها عن الأضرار الناجمة عن الممارسات الخاطئة.'
        },
        {
           question: 'كيف ابدأ بالتقييم الذاتي ؟' ,
           answer: (
               <div>
                    <p>كل ما عليك هو القيام بالآتي:</p>
                    <ul>
                        <li>1- التوجّه إلى الصفحة الرئيسية والضغط على تسجيل الدخول.</li>
                        <li>  2-  الموافقة على الشروط و الأحكام . </li>
                        <li> 3- تسجيل الدخول باستخدام بيانات المنشأة المسجلة في الخدمات الإلكترونية لدى وزارة الموارد البشرية والتنمية الاجتماعية (الدخول الموحد لخدمات الوزارة).  </li>
                        <li> 4- ومن ثمّ البدء بعملية التقييم الذاتي بالإجابة على جميع المعايير الخاصة بكل منشأة، وعند الإجابة على اسئلة التقييم يرجى التأكد من إرفاق الملفات المطلوبة. </li>
                    </ul>
                    <p>في حال لم يكن لديك حساب لدى وزارة الموارد البشرية والتنمية الاجتماعية:</p>
                    <ul>
                        <li>1-توجه إلى بوابة الخدمات الالكترونية الخاصة بوزارة الموارد البشرية والتنمية الاجتماعية والنقر على "إنشاء حساب"</li>
                        <li>2-بعد إنشاء الحساب اتّبع الخطوات المذكورة أعلاه (1-4).</li>
                    </ul>
               </div>
           )
        },
        {
            question: 'هل أستطيع تغيير إجاباتي في وقت لاحق بعد إنهاء التقييم؟',
            answer: 'لا، لا يمكن تغيير الإجابات للتقييم الذاتي بعد الانتهاء منه.'
        },
        {
            question: 'هل سيترتب على المنشأة أيّ غراماتٍ مالية إذا لم تكن ملتزمةً بأحد المعايير في مرحلة التقييم الذاتي؟',
            answer: 'في الفترة الحاليّة لن يترتب على المنشأة أيّ غرامات مالية.'
        },
        {
            question: 'ماذا لو كانت نتائج التقييم الذاتي للمنشأة غير جيّدة؟',
            answer: 'الهدف من مرحلة التقييم الذاتي أن تتعرّف المنشأة على نسبة امتثالها لمتطلبات السلامة والصحة المهنية ثمّ العمل على تصحيح الأخطاء والمخالفات، ولن يترتب على المنشأة أي غرامات مالية في هذه المرحلة.'
        },
        {
            question: 'هل يجب على المنشأة الامتثال بجميع معايير وأنظمة وزارة الموارد البشرية والتنمية الاجتماعية؟',
            answer: 'نعم، يجب على المنشأة الالتزام بجميع معايير وأنظمة وزارة الموارد البشرية والتنمية الاجتماعية ومتطلبات المجلس الوطني للسلامة والصحة المهنية.'
        },
        {
           question: 'هل يوجد حدٌّ أدنى للامتثال؟' ,
           answer: 'نعم، يتعين على المنشأة أن تلتزم بما لا يقل عن 60% من معايير وأنظمة وزارة الموارد البشرية والتنمية الاجتماعية، بالإضافة إلى متطلبات المجلس الوطني للسلامة والصحة المهنية.'
        },
        {
            question: 'كيف تطّلع المنشأة على نتائج التقييم الذاتي فيما بعد؟',
            answer: 'يمكن للمنشأة الاطلاع على نتائج التقييم النهائي بتسجيل الدخول الى البوابة والضغط على خيار "تنزيل التقرير".'
        },
        {
            question: 'ماذا يقصد بخيار "لا ينطبق" عند الإجابة على التقييم الذاتي؟',
            answer: 'يُستخدم هذا الخيار عندما لا ينطبق أحد المعايير على المنشأة كما يتطلب إضافة إفادة في هذه الحالة.'
        },
        {
           question: 'ماذا يحصل إذا لم تلتزم المنشأة بأحد المعايير؟',
           answer: ' يجب على المنشأة اتخاذ إجراءات فورية للتصحيح والامتثال بهذا المعيار، لضمان بيئة عمل سليمة وآمنة.'
        },
      ]
    },
    {
      title: 'التسجيل ',
      faqs: [
         {
          question: 'كيف تقوم المنشأة بتسجيل الدخول؟',
          answer: (
              <div>
                <p> بالتوجّه إلى الصفحة الرئيسية لموقع منصة تقييم السلامة والصحة المهنية ثم: </p>
                <ul>
                  <li>الضغط على تسجيل الدخول إذا كنت تمتلك بيانات دخول في بوابة الخدمات الإلكترونية لوزارة الموارد البشرية والتنمية الاجتماعية.</li>
                </ul>
              </div>
          )
        },
      ]
    },
    {
      title: 'وسائل التواصل',
      faqs: [
          {
             question: 'كيف أستطيع التواصل مع فريق منصة تقييم السلامة والصحة المهنية؟',
             answer: 'يمكنكم كتابة استفساراتكم او طلبات الدعم الفني عن طريق خيار "اترك لنا رسالة" في أسفل الصفحة حيث يطلب منكم توفير بيانات التواصل وسيتم الرد في أقرب وقت ممكن. '
          },
      ]
    },
  ];
const toggleAnswer = (sectionIndex: number, faqIndex: number) => {
  const currentIndex = sectionIndex * 10 + faqIndex;

  if (openIndexes.includes(currentIndex)) {
    setOpenIndexes(prev => prev.filter(index => index !== currentIndex));
  } else {
    setOpenIndexes([currentIndex]);
  }
};
  return (
      <div className={classes.downPage}>
           <header className={classes.header} dir="ltr">
            <Container maxWidth="lg" className={classes.main}>
              <div className={classes.imageWrapper}>
                <img alt="osh" src={OshStripes} />
                <img src={LogoColor} alt="Logo-color" className={classes.logo} />
              </div>
            </Container>
          </header>
          <div className={classes.addPadding} style={{ backgroundColor: 'white' }}>
              <div className={classes.titleWrapper}>
                  <Typography className={classes.title} variant={isUpMd ? 'h4' : 'h6'}>
                    الأسئلة الشائعة
                  </Typography>
                  <Link className={classes.backButtonFag} href="/" underline="none">
                    <Button className={classes.button} onClick={handleBackToHome}>
                      العودة إلى الصفحة الرئيسية
                    </Button>
                  </Link>
              </div>
           {faqSections.map((section, sectionIndex) => (
          <div key={sectionIndex} className={classes.items}>
            <h2 className={classes.subtitle}>{section.title}</h2>
            {section.faqs.map((faq, faqIndex) => (
              <Card key={faqIndex} className={classes.panelCard}>
                <CardContent>
                  <Box className={classes.box}>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                      {faq.question}
                    </Typography>
                    <CollapseButton onClick={() => toggleAnswer(sectionIndex, faqIndex)} />
                  </Box>
                  {openIndexes.includes(sectionIndex * 10 + faqIndex) && (
                    <Typography className={classes.answerGroup}>
                      {faq.answer}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
        ))}
      </div>

      </div>
  );
};