import { makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';

import {
  useGetAssessmentByIdQuery,
  useGetEstablishmentQuery,
} from 'domain/company-representative/api/companyRepresentative.api';
import { useCreateTranslate } from 'utilities/translate.hook';
import { SelfAssessmentPassingStatus } from 'types/Assessment';
import { TranslationNamespace } from 'i18n/config';
import { getAttemptsMessageFn } from 'domain/company-representative/utils/getAttemptsMessageFn';
import { useNavigate } from 'utilities/navigate';
import {
  CompanyRepresentativeRoutes,
  getCompanyRepresentativePath,
} from 'domain/company-representative/CompanyRepresentativeRoutes';
import { establishmentToEstablishmentInfo } from 'types/Establishment';

import { Layout } from './Layout';
import { LoadingWrapper } from './LoadingWrapper';
import { labelCardColor, shdShadow } from './styles';
import { ResultMarker } from './ResultMarker';
import { Button } from './Button';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
    boxShadow: shdShadow,
    textAlign: 'center',
  },

  resultHeader: {
    marginTop: theme.spacing(5),
    fontWeight: 'bold',
    color: labelCardColor,
  },

  result: {
    marginTop: '12px',
  },

  description: {
    marginTop: theme.spacing(3),
    color: 'rgba(0, 0, 0, 0.6)',
  },

  buttons: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  button: {
    width: '276px',
    height: '50px',
    marginRight: theme.spacing(4),
  },

  firstButton: {
    flip: false,
    marginRight: theme.spacing(2),
  },
}));

type FinishedAssessmentProps = {
  handleRetry?: () => void | Promise<void>;
  handleBackNavigate: () => void;
  assessmentId: number;
  translationNamespace: TranslationNamespace.companyRepresentative | TranslationNamespace.admin;
  isPcRequest?: boolean;
};

export const FinishedAssessment = ({
  assessmentId,
  handleRetry,
  handleBackNavigate,
  translationNamespace,
  isPcRequest,
}: FinishedAssessmentProps) => {
  const { data: assessment, isLoading, isFetching } = useGetAssessmentByIdQuery(assessmentId);
  const { data: establishment } = useGetEstablishmentQuery(assessment?.establishmentId || 0, {
    skip: isPcRequest || !assessment?.establishmentId,
  });

  const establishmentInfo = establishment && establishmentToEstablishmentInfo(establishment);

  const classes = useStyles();
  const translate = useCreateTranslate(translationNamespace);
  const navigate = useNavigate();
  const getAttemptsMessage = getAttemptsMessageFn(translate);

  const getResultDialogMessageFn = (passingStatus: SelfAssessmentPassingStatus | null, score: number = 0) => {
    switch (passingStatus) {
      case SelfAssessmentPassingStatus.BELOW_PASSING_SCORE:
        return translate('failedWithScore', {
          score: `${score}`,
        });
      case SelfAssessmentPassingStatus.ABOVE_HIGH_SCORE_VALUE:
      case SelfAssessmentPassingStatus.BELOW_HIGH_SCORE_VALUE:
        return translate('passedWithScore', {
          score: `${score}`,
        });
      default:
        throw Error('Unsupported passing SA status');
    }
  };

   const companyRepresentativeBtns = (
    <>
      {assessment?.passingStatus === SelfAssessmentPassingStatus.BELOW_PASSING_SCORE ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            className={`${classes.button} ${classes.firstButton}`}
            onClick={handleRetry}
            disabled={establishmentInfo?.assessmentAttemptsLeftOSH === 0}
          >
            {translate('retry')}
          </Button>
        </>
      ) : (
        <>
         <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              navigate(
                `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${assessmentId}${
                  CompanyRepresentativeRoutes.result
                }`
              );
            }}
          >
            {translate('seeAnswers')}
          </Button>
        </>
      )}
    </>
  );

  // const companyRepresentativeBtns = (
  //   <>
  //     {assessment?.passingStatus === SelfAssessmentPassingStatus.BELOW_PASSING_SCORE ? (
  //       <>
  //         <Button
  //           variant="contained"
  //           color="secondary"
  //           className={`${classes.button} ${classes.firstButton}`}
  //           onClick={handleRetry}
  //           disabled={establishmentInfo?.assessmentAttemptsLeftOSH === 0}
  //         >
  //           {translate('retry')}
  //         </Button>

  //         <Button
  //           variant="contained"
  //           color="secondary"
  //           className={classes.button}
  //           onClick={() => {
  //             navigate(
  //               `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${assessmentId}${
  //                 CompanyRepresentativeRoutes.result
  //               }`
  //             );
  //           }}
  //         >
  //           {translate('seeAnswers')}
  //         </Button>
  //       </>
  //     ) : (
  //       <>
  //         <Button
  //           variant="contained"
  //           color="secondary"
  //           className={classes.button}
  //           onClick={() => {
  //             navigate(
  //               `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${assessmentId}${
  //                 CompanyRepresentativeRoutes.payment
  //               }`
  //             );
  //           }}
  //         >
  //            {translate('continueToPayment')}
             
  //         </Button>
  //       </>
  //     )}


  //     <Button variant="outlined" color="secondary" className={classes.button} onClick={handleBackNavigate}>
  //       {translate('goBackFromAssessmentResults')}
        
  //     </Button>
  //   </>
  // );

  return (
    <Layout maxWidth="lg">
      <LoadingWrapper isLoading={isLoading || isFetching}>
        {assessment && assessment.passingStatus !== null && (
          <Paper className={classes.main}>
            <ResultMarker isPassed={assessment.isPassed} wrapperHeight={96} markHeight={64} />
            <Typography data-testid="finished-assessment-result" variant="body2" className={classes.resultHeader}>
              {translate('result')}
            </Typography>
            <Typography variant="h5" className={classes.result}>
              {getResultDialogMessageFn(assessment.passingStatus, assessment.score)}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {handleRetry // TODO: attempts left passed is only for one (OSH), so when CA is added, this should be updated to pass both attempts left
                ? getAttemptsMessage(assessment.passingStatus, establishmentInfo?.assessmentAttemptsLeftOSH)
                : translate('finishedAudit')}
            </Typography>
            {/* <Typography variant="body1" className={classes.description}>
              {handleRetry
                ? getAttemptsMessage(assessment.passingStatus, establishmentInfo?.assessmentAttemptsLeftOSH)
                : translate('finishedAudit')}
            </Typography> */}
            {/* <div dir="ltr" className={classes.buttons}>
              {companyRepresentativeBtns}
            </div> */}
            <div dir="ltr" className={classes.buttons}>
              {handleRetry}
                <Button variant="outlined" color="secondary" className={classes.button} onClick={handleBackNavigate}>
                 {translate('goBackFromAssessmentResults')}
               </Button>
            </div>
          </Paper>
        )}
      </LoadingWrapper>
    </Layout>
  );
};
