import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import i18n from 'i18next';

import { getDirection } from '../utilities/useLanguage';

type PCRequestAssessmentQuestionsProps = {
  value?: number | string;
};

const useStyles = makeStyles((theme) => ({
  sectionNumberWrapper: {
    flexShrink: 0,
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
  },
}));

export const CircleWrapper: React.FC<PCRequestAssessmentQuestionsProps> = ({ children, value }) => {
  const classes = useStyles();
  const direction = getDirection(i18n.language);

  return (
    <div className={classes.sectionNumberWrapper}>
      {children || (
        <Typography dir={direction} color="primary" variant="h5">
          {value}
        </Typography>
      )}
    </div>
  );
};
