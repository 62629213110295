import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { CheckGreyIcon, CrossCloseGreyIcon } from 'ui/icons/icons';

type ResultMarkerProps = { isPassed: boolean; wrapperHeight: number; markHeight?: number };

const useStyles = makeStyles<Theme, ResultMarkerProps>((theme) => {
  const getMarkColor = ({ isPassed }: ResultMarkerProps) =>
    isPassed ? theme.palette.primary.main : theme.palette.error.light;
  const getWrapperHeight = ({ wrapperHeight }: ResultMarkerProps) => `${wrapperHeight}px`;
  const getMarkHeight = ({ wrapperHeight, markHeight }: ResultMarkerProps) => `${markHeight || wrapperHeight}px`;

  return {
    markWrapper: {
      height: getWrapperHeight,
      width: getWrapperHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: (props) => `1px solid ${getMarkColor(props)}`,
      borderRadius: '50%',
    },

    mark: {
      height: getMarkHeight,
      width: getMarkHeight,
      color: getMarkColor,
    },
  };
});

export const ResultMarker: React.FC<ResultMarkerProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.markWrapper}>
      <SvgIcon className={classes.mark} component={props.isPassed ? CheckGreyIcon : CrossCloseGreyIcon} />
    </div>
  );
};
