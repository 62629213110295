import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { Button } from 'ui/Button';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { Dialog } from 'ui/Dialog';

import { useGetQuestionnaireByIdQuery, usePublishQuestionnaireMutation } from '../api/admin.api';

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  questionnaireId: number;
  navigateToDashboard: () => void;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: 'center',
  },

  description: {
    marginTop: theme.spacing(2),
    minHeight: '40px',
    color: theme.palette.text.secondary,
  },

  buttons: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    //     alignItems: 'center',
    marginBottom: theme.spacing(2),
  },

  submitButton: {
    flex: 1,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },

  cancelButton: {
    flex: 1,
    flip: false,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

export const AdminQuestionnairePublishDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  questionnaireId,
  navigateToDashboard,
}) => {
  const classes = useStyles();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const [patchQuestionnaire, { isLoading }] = usePublishQuestionnaireMutation();
  const showNotification = useNotifications();
  const { data: questionnaire } = useGetQuestionnaireByIdQuery(questionnaireId);

  const publishQuestionnaire = async () => {
    if (!isLoading && questionnaireId) {
      await patchQuestionnaire({
        id: questionnaireId,
      })
        .unwrap()
        .then(() => {
          showNotification(NotificationType.Success, { message: translateQuestionnaire('newSelfAssessmentAdded') });

          navigateToDashboard();
        })
        .catch((err: BaseQueryError) => {
          showNotification(NotificationType.Error, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            errorId: err.data.errorCode,
          });
        })
        .finally(() => {
          handleClose();
        });
    }
  };

  return (
    <Dialog open={open} handleClose={handleClose} className={classes.dialog}>
      <Typography variant="h6">{translateQuestionnaire('areYouSureToPublish')}</Typography>
      <Typography variant="body2" className={classes.description}>
        {translateQuestionnaire('areYouSureToPublishDescription', {
          minScore: `${questionnaire?.minSuccessfulScore || 0}`,
        })}
      </Typography>
      <div dir={i18n.language === 'en' ? 'ltr' : 'rtl'} className={classes.buttons}>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          className={classes.submitButton}
          onClick={publishQuestionnaire}
        >
          {translateQuestionnaire('publish')}
        </Button>
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          className={classes.cancelButton}
          onClick={handleClose}
        >
          {translateCommon('cancel')}
        </Button>  
      </div>
    </Dialog>
  );
};
